
div.DraftEditor-root {
    border: 1px solid #C0C0C0;
    max-height: 400px;
    width: auto;
    overflow-y: auto;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    height: 100%;
}
