body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

.FullScreenTabs {
  display: flex;
  overflow: hidden;
  height: 100%;
  flex-direction: column;
}

.FullScreenTabs > .tab-content {
  height: 100%;
  overflow: hidden;
}

.FullScreenTabs > .tab-content > .tab-pane {
  height: 100%;
  overflow: hidden;
}

.navbar {
  padding: 0 !important;
}

.nav-link {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.nav-tabs .dropdown-menu {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}
