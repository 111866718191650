
.ws-pre-wrap {
	word-wrap: break-word;
	white-space: pre-wrap;
}

.HTMLEditor {
	font-family: Arial, serif;
	font-size: 11px;
}

.HTMLEditor h1 {
	font-size: 16px;
}
